import { Form, Input } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { validateEmail } from 'utils/common';

interface IProps {
  listEmail: any;
  answersEmail: (e, data: any) => void;
}

export default function ListEmail({ listEmail, answersEmail }: IProps) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  return (
    <>
      <Form.Item
        className={listEmail?.is_required ? 'email' : ' before'}
        name={listEmail?._id}
        label={
          !listEmail?.is_required ? (
            listEmail?.question_name
          ) : (
            <Trans
              i18nKey="report.new.question_lists.required.test"
              components={{
                question: listEmail?.question_name,
                red: <span style={{ display: 'inline-block', color: 'red' }} />,
                default: <span style={{ display: 'inline-block', color: 'black' }} />,
              }}
            />
          )
        }
        rules={[
          {
            required: listEmail?.is_required,
            message: t('report.new.question_lists.required'),
          },
          () => ({
            validator(_rule, value) {
              if (!listEmail?.is_required && !value) return Promise.resolve();

              if (!value) return Promise.reject();

              const checkEmail: boolean = validateEmail(value);

              if (checkEmail) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t('report.field.email.invalid')));
            },
          }),
        ]}
      >
        <Input
          maxLength={256}
          placeholder={t('report.new.email')}
          className="input"
          onChange={(e) => answersEmail(e, listEmail)}
        />
      </Form.Item>
    </>
  );
}
