// eslint-disable-next-line react-hooks/exhaustive-deps
import { Row, Col, Form, Button, Typography, Checkbox, Spin, Space } from 'antd';
import { Content, Footer } from 'antd/lib/layout/layout';
import { LeftOutlined } from '@ant-design/icons';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { useState } from 'react';
import { apiPostValueSelect } from '../../api';
import { storage } from 'utils';
import { useTranslation } from 'react-i18next';
import HeaderComponent from 'components/Layout/components/header/HeaderComponent';
import './index.scss';
import axios from 'axios';
import SymbolAuth from 'components/common/icon-svg/symbolAuth';
import { API_URL } from 'config';
import { showError } from 'components/common/standby-notice';
import ModalTerm from 'components/Layout/components/SiderBar/ModalTerm';
import Cookies from 'js-cookie';
import { EType } from 'components/Layout/components/SiderBar/types';
import { get } from 'lodash';
import { removeAllData } from 'lib';

const { Title, Text } = Typography;

let modalTermVisible;

function TermsPage() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const state = storage.getCurrentState();
  const history = useHistory();
  const location: any = useLocation();
  const locationState = location?.state;
  const [form] = Form.useForm();

  const HandleLoginClick = (values) => {
    setIsLoading(true);

    if (!values.challenger_answer) {
      showError(t('login.input.term.required'));
      return setIsLoading(false);
    } else {
      apiPostValueSelect({
        ...locationState?.data,
        challenger_answer: values.challenger_answer.toString(),
      })
        .then((res) => {
          storage.clearCurrentState();
          setValue(res);
        })
        .catch((err) => {
          if (err) {
            if (err?.status !== 503) {
              showError(t(`errors.code.${err?.data?.error_code}`));
            }
            removeAllData();
            return history.push('/auth/login');
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const setValue = (res: any) => {
    let data: object = {};
    data = {
      org_data: [
        {
          organization_id: res.data.organization_id,
          organization_name: res.data.organization_name,
          access_token: res.data.authentication.access_token,
          actived_user_id: res.data.actived_user_id,
        },
      ],
      platform: locationState?.platform,
    };

    const CurentOrganization: object = {
      organization_id: res.data.organization_id,
      organization_name: res.data.organization_name,
      access_token: res.data.authentication.access_token,
      actived_user_id: res.data.actived_user_id,
    };
    storage.setRefreshToken(res.data?.authentication?.refresh_token);
    storage.setClientId(res?.data?.client_id);
    storage.setCurrentUser(data);
    storage.setCurentOrganization(CurentOrganization);
    storage.setToken(res.data.authentication.access_token);
  };

  const goBack = () => {
    history.push(state?.list[state?.list?.length - 1]?.pathname);

    if (state?.list[state.list.length - 1].challenger_answer) {
      const dataChange = {
        ...state?.list[state.list.length - 2],
        selectedValue: state?.list[state.list.length - 1].challenger_answer,
        selected_changeller_name: state?.list[state.list.length - 1].challenger_name,
        selected_choicename: state?.list[state.list.length - 1].choice_name,
        selected_session: state?.list[state.list.length - 1].session,
        selected_name: state?.list[state.list.length - 1].name,
      };

      state.list[state.list.length - 2] = dataChange;

      state.list.splice(state.list.length - 1, 1);

      storage.setCurrentState(state);
    }
  };

  const handleShowModalPDF = (type: string) => {
    getPreviewPDF(type);
  };

  const getPreviewPDF = async (type: string) => {
    const query = {
      platform: 'WEB_APP',
      category: type,
    };
    try {
      const result = await axios.get(API_URL + '/terms', { params: query });
      const url = get(result, 'data.data.0.url');
      if (url) {
        modalTermVisible?.({
          visible: true,
          type,
          url,
        });
      }
    } catch (error) {
      showError(t('file.preview.error'));
    }
  };

  if (!state) {
    return <Redirect to="/auth/login" />;
  }
  const currentLanguageCode = Cookies.get('i18next') || 'ja';

  return (
    <>
      <HeaderComponent />
      <Content className="layout_term">
        <Spin spinning={isLoading} className="spin-auth">
          <Row className="container">
            <Col className="term-col" xs={20} sm={16} md={16} lg={12}>
              <Text className="title-back" onClick={goBack} style={{ display: 'block' }}>
                <LeftOutlined style={{ marginRight: '10px' }} />
                {t('login.back')}
              </Text>
              <div className="card">
                <div className="card-img">
                  <div className="card-top">
                    <img className="top-image" src="/image/background.png" alt="" />
                  </div>
                  <div className="logo">
                    <SymbolAuth />
                    {t('STANDBY')}
                  </div>
                </div>

                <Form
                  form={form}
                  className="form"
                  style={{ textAlign: 'center' }}
                  onFinish={HandleLoginClick}
                >
                  <Title level={5} className="form-title">
                    {t('login.selected')}{' '}
                  </Title>
                  <Title level={4} className="orzgation_choice">
                    {locationState?.choice_name}
                  </Title>
                  <Form.Item className="container-checkbox">
                    <Form.Item
                      className="item-form"
                      name="challenger_answer"
                      valuePropName="checked"
                    >
                      <Checkbox className="btn-check" name="checkbox"></Checkbox>
                    </Form.Item>
                    <Form.Item className="item-form">
                      <Title level={5} className="title-accept">
                        <Space className="space" size={4}>
                          {currentLanguageCode !== 'ja' && <Text>{t('login.accept')}</Text>}
                          <Text
                            className="title-term"
                            onClick={() => handleShowModalPDF(EType.TERM)}
                          >
                            {t('login.title.terms')}
                          </Text>
                          <Text style={{ cursor: 'unset' }}>{t('login.title.and')}</Text>
                          <Text
                            className="title-term"
                            onClick={() => handleShowModalPDF(EType.PRIVACY)}
                          >
                            {t('login.title.policy')}
                          </Text>
                          {currentLanguageCode === 'ja' && <Text>{t('login.accept')}</Text>}
                        </Space>
                      </Title>
                    </Form.Item>
                  </Form.Item>
                  <Form.Item className="form-item">
                    <Button type="primary" className="btn" htmlType="submit">
                      {t('login.register')}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Col>
          </Row>
          <Footer
            className="footer_term"
            style={{ background: 'transparent', padding: '16px 0 14px 0' }}
          >
            {t('©2021 STANDBY.')}
            <Title
              level={5}
              className="footer_title"
              onClick={() => handleShowModalPDF(EType.TERM)}
            >
              {t('footer.term_of_use')}
            </Title>
          </Footer>
        </Spin>
      </Content>
      <ModalTerm onRef={(ref) => (modalTermVisible = ref)} />
    </>
  );
}
export default TermsPage;
