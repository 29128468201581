import { API_URL } from 'config';

import io from 'socket.io-client';

export interface IMsgNotification {
  client_id: string;
  message: object;
  org_id: string;
}

let socket: any;

export const initialSocket = (token: string, roomId: string) => {
  socket = io(`${API_URL}/chat`, {
    transports: ['websocket'],
    secure: true,
    path: '/socket.io',
    query: { token, from: 'anonymouse' },
  });
  onConnect(roomId);
  onPing();
};

let socketNotification: any;
export const initialNotification = (token: string) => {
  socketNotification = io(`${API_URL}/notifications`, {
    path: '/socket.io',
    transports: ['websocket'],
    secure: true,
    query: { token, from: 'anonymouse' },
  });
};

/**
 * @socket
 * @emit
 */

export const joinRoom = (room: string) => {
  if (socket) {
    return socket.emit('joinRoom', room);
  }
};

export const leaveRoom = (room: string) => {
  if (socket) {
    return socket.emit('leaveRoom', room);
  }
};

export const sendMessage = (room: string, message: any) => {
  if (socket) {
    if (message?.files > 0) {
      return socket.emit('msgToServer', { ...message, room });
    } else {
      return socket.emit('msgToServer', { text: message, room });
    }
  }
};

/**
 * @socket
 * @on
 */

export const onReceiveMessage = (callback: (msg: any) => void) => {
  if (socket) {
    return socket.on('msgToClient', (msg: any) => {
      callback(msg);
    });
  }
};

export const onReceiveNotification = (client_id, callback: (msg: IMsgNotification) => void) => {
  if (socketNotification) {
    return socketNotification.on(`new_message_anonymouse_${client_id}`, (msg: IMsgNotification) => {
      callback(msg);
    });
  }
};

export const onReceiveNotificationBroadCast = (callback: (msg: any) => void) => {
  if (socketNotification) {
    return socketNotification.on(`new_admin_broadcast`, (msg: any) => {
      callback(msg);
    });
  }
};
export const onReceiveNotice = (callback: (msg: any) => void) => {
  if (socketNotification) {
    return socketNotification.on(`new_cms_content`, (msg: any) => {
      callback(msg);
    });
  }
};

export const onStartMaintaince = (callback: (msg: any) => void) => {
  if (socketNotification) {
    const onEvent: string = `start_maintaince`;
    return socketNotification.on(onEvent, (msg: any) => {
      callback(msg);
    });
  }
};

export const onStopMaintaince = (callback: (msg: any) => void) => {
  if (socketNotification) {
    const onEvent: string = `stop_maintaince`;
    return socketNotification.on(onEvent, (msg: any) => {
      callback(msg);
    });
  }
};

export const onReceiveAll = (callback: (msg: any) => void) => {
  if (socketNotification) {
    return socketNotification.on(`new_admin_content`, (msg: any) => {
      callback(msg);
    });
  }
};
export const onHandleErrorSocket = (callback: (msg: any) => void) => {
  if (socket) {
    return socket.on('Error', (msg: any) => {
      callback(msg);
    });
  }
};

export const onConnect = (roomId: string) => {
  if (socket) {
    return socket.on('connect', () => {
      console.log('🚀 ~ onConnect');
      joinRoom(roomId);
    });
  }
};

export const onJoinRoom = (callback: (room: string) => void) => {
  if (socket) {
    return socket.on('joinedRoom', (room: string) => {
      callback(room);
    });
  }
};

export const onLeftRoom = (callback: (room: string) => void) => {
  if (socket) {
    return socket.on('leftRoom', (room: string) => {
      callback(room);
    });
  }
};

/**
 * Disconnect socket
 */

export const disconnectSocket = () => {
  if (socket) {
    return socket.disconnect();
  }
};
export const disconnectSocketNotification = () => {
  if (socketNotification) {
    return socketNotification.disconnect();
  }
};

export const onPing = () => {
  socket.on('ping', () => {});
};
