import { call, fork, put, take, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { getCountUnReads } from 'features/content/api';
import {
  getCountNewMessage,
  getIncidentList,
  getListInicdentNotQuery,
  getListMessageChat,
  IQueryGetIncidentDetail,
  IQueryGetListIncident,
} from './api';
import { IPayloadAppendMessageIncidents, messageActions } from './messageSlice';
let OK = 'OK';

function* getChats(query: IQueryGetIncidentDetail) {
  try {
    if (query.reset) {
      yield put(messageActions.getListMessageSuccess([]));
    } else {
      const get = yield call(getListMessageChat, query);
      if (get?.status === OK) {
        const { data } = get;
        let newData: any = [];
        if (Array.isArray(data)) {
          newData = data;
        }
        yield put(messageActions.getListMessageSuccess(newData));
      }
    }
  } catch (error) {
    yield put(messageActions.getListMessageFailed(error));
  }
}

function* getListIncident(query: IQueryGetListIncident) {
  try {
    const get = yield call(getIncidentList, query);

    if (get?.status === OK) {
      const { data } = get;
      const newdata: any[] = data.map((e) => {
        return {
          incident_id: e.incident_id,
          incident_number: e.incident_number,
          last_message: e.last_message?.text,
          message_unreads: e.message_unreads,
          total_message: e.total_message,
          org_id: e.last_message?.org_id,
        };
      });
      yield put(messageActions.getListIncidentReportSuccess(newdata));
    }
  } catch (error) {
    yield put(messageActions.getListIncidentReportFailed(error));
  }
}

function* getNotfications() {
  try {
    const result = yield call(getCountNewMessage);
    if (result?.status === 'OK') {
      yield put(messageActions.updateTotalnotificationSuccess(result?.data?.message_unreads));
    }
  } catch (error) {}
}

function* getCountNotice(query) {
  try {
    console.log('query:', query);
    const result = yield call(getCountUnReads, query);
    if (result?.status === 'OK') {
      yield put(messageActions.updateTotalContentSuccess(result?.data?.unreads));
    }
  } catch (error) {}
}

function* getListIncidentDefault(payload) {
  try {
    const get = yield call(getListInicdentNotQuery);
    if (get?.status === OK) {
      const { data } = get;
      const newdata: any[] = data.map((e) => {
        return {
          incident_id: e.incident_id,
          incident_number: e.incident_number,
          last_message: e.last_message?.text,
          message_unreads: e.message_unreads,
          total_message: e.total_message,
          org_id: e.last_message?.org_id,
        };
      });

      yield put(messageActions.getListIncidentNotParamsSuccess(newdata));
    }
  } catch (error) {
    yield put(messageActions.getListIncidentNotParamsFailed(error));
    if (payload?.onError) yield payload?.onError(error);
  }
}
function* appendMessage(payload: IPayloadAppendMessageIncidents) {
  try {
    if (payload.msg) {
      yield put(messageActions.appendMessageSuccess(payload.msg));
    }
  } catch (error) {
    yield put(messageActions.getListMessageFailed(error));
  }
}

function* watchLoadingListMessage() {
  while (true) {
    const action: PayloadAction<IQueryGetIncidentDetail> = yield take(
      messageActions.getListMessageSaga.type
    );
    yield fork(getChats, action.payload);
  }
}

function* watchAppendListMessage() {
  while (true) {
    const action: PayloadAction<IPayloadAppendMessageIncidents> = yield take(
      messageActions.appendListMessageSaga.type
    );
    yield fork(appendMessage, action.payload);
  }
}

function* watchLoadingListIncidentReport() {
  while (true) {
    const action: PayloadAction<IQueryGetListIncident> = yield take(
      messageActions.getListIncidentReport.type
    );
    yield fork(getListIncident, action.payload);
  }
}

function* watchLoadingListIncidentNotParams() {
  while (true) {
    const action = yield take(messageActions.getListIncidentNotParams.type);
    yield fork(getListIncidentDefault, action.payload);
  }
}

function* watchAppendNotifications() {
  while (true) {
    const action = yield take(messageActions.updateTotalnotification.type);
    yield fork(getNotfications);
  }
}

function* watchAppendCountContent() {
  while (true) {
    const action = yield take(messageActions.updateTotalContent.type);
    yield fork(getCountNotice, action.payload);
  }
}

export default function* messageSaga() {
  yield fork(watchLoadingListIncidentNotParams);
  yield fork(watchLoadingListIncidentReport);
  yield fork(watchLoadingListMessage);
  yield fork(watchAppendListMessage);
  yield fork(watchAppendNotifications);
  yield fork(watchAppendCountContent);
}
