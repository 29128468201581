import { Card, Col, Row, Typography, Button, Modal, Spin } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { showError, showSuccess } from 'components/common/standby-notice';
import axios from 'axios';

import {
  getIncidentList,
  getListInicdentNotQuery,
  IPostReadChatIncidentFormValues,
  IQueryGetIncidentDetail,
  postHideIncident,
  postReadChatAnonymouse,
  IPostHideIcident,
} from 'features/message/api';
import { messageActions, selectListChats } from 'features/message/messageSlice';
import { sendMessage } from 'lib/socket';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { storage } from 'utils';
import './chat.scss';
import FormChat from './FormChat';
import ListMessage from './ListMessage';
import { postAttchmentFile, upLoad } from 'features/incident/api';
import ListIncident from './ListIncident';
import WhiteBox from 'components/common/icon-svg/whiteBox';
import nProgress from 'nprogress';
import I18n from 'config/i18n';
import { IMsgNotification, onReceiveNotification, initialNotification } from 'lib/socket';

const { Text } = Typography;

export interface incidentReportSubmit {
  files: any[];
}

interface IChatPageProps {
  countNewMessageUnread: () => void;
}
const ChatPage = ({ countNewMessageUnread }: IChatPageProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [incidentId, setIncidentId] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false);
  const [isLoadingListIncident, setIsLoadingListIncident] = useState<boolean>(false);
  const [itemSelected, setItemSelected] = useState<any>(null);

  const inputRef: any = useRef();
  const inputFileRef: any = useRef();
  const chats = useSelector(selectListChats);
  const [FileMessage, setFileMessage] = useState<incidentReportSubmit>({
    files: [],
  });
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [listIncident, setListIncdent] = useState<any[]>();
  const [isClosedInputChat, setIsClosedInputChat] = useState<boolean>(false);
  // const itemSelected?.incident_id: string = storage.getActiveId();
  let incident_number: number = itemSelected?.incident_id
    ? listIncident?.find((e) => e?.incident_id === itemSelected?.incident_id)?.incident_number
    : '';

  const values: any = (location.state as any)?.values;

  const passcode_enabled: boolean = storage.getCurentPasscodeEnable();
  const client_id = storage.getClientId();

  useEffect(() => {
    checkPassCode();
  }, []);

  useEffect(() => {
    if (client_id) {
      initialNotificationMesage();
      onReceiveNotification(client_id, (msg: IMsgNotification) => {
        if (msg) {
          reloadListIncident();
        }
      });
    }
  }, []);

  const initialNotificationMesage = () => {
    const token: string = storage.getToken();
    if (client_id && token) {
      initialNotification(token);
    }
  };

  function chooseValue(item: any) {
    // check truong hop dang chat ma bi blockkkk
    // sau khi chuyen incident se dua ve false de incident is_close = false hien thi len
    setIsClosedInputChat(false);

    setItemSelected(item);
    reloadListIncident();
    setFileMessage({ ...FileMessage, files: [] });
    if (!inputRef?.current) return;
    inputRef?.current!.resetFields();
  }
  const ShowModal = (id: any) => {
    setIsModalVisible(true);
    setIncidentId(id);
  };

  const checkRenderListMessage = () => {
    if (!itemSelected) return false;
    return true;
  };

  const checkRenderInputChat = () => {
    if (!itemSelected) return false;

    if (itemSelected.is_closed) return false;
    if (isClosedInputChat) return false;
    return true;
  };

  const handleHideIncident = () => {
    if (incidentId) {
      const data: IPostHideIcident = {
        incident_id: incidentId,
      };
      postHideIncident(data)
        .then((res) => {
          if (Array.isArray(listIncident) && listIncident.length > 1) {
            if (itemSelected?.incident_id === incidentId) {
              const index = listIncident.findIndex(
                (e) => e.incident_id === itemSelected?.incident_id
              );
              storage.clearActiveId();
            }
            reloadListIncident();
            setItemSelected(null);
          } else {
            storage.clearActiveId();
            reloadListIncident();
            setItemSelected(null);
          }
          countNewMessageUnread();
          dispatch(messageActions.getListMessageSaga({ reset: true }));
        })
        .catch((err) => {})
        .finally(() => {
          setIsModalVisible(false);
        });
    }
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const loadingGetListMessageChat = (id: string) => {
    if (id && id !== '') {
      const query: IQueryGetIncidentDetail = {
        incident_id: id,
      };
      dispatch(messageActions.getListMessageSaga(query));
    }
  };

  const handleListIncidentSuccess = (data = []) => {
    if (data.length === 0) {
      setListIncdent([]);
      return;
    }
    const newdata: any[] = data.map((e: any) => {
      return {
        incident_id: e.incident_id,
        incident_number: e.incident_number,
        last_message: e.last_message?.text,
        files: e.last_message?.files,
        type: e.last_message?.type,
        message_unreads: e.message_unreads,
        total_message: e.total_message,
        org_id: e.last_message?.org_id,
        is_admin: e.last_message?.is_admin,
        createdAt: e.last_message?.createdAt,
        is_closed: e.is_closed,
      };
    });
    setListIncdent(newdata);
  };

  const handleListIncidentError = (err: any) => {};

  const checkPassCode = useCallback(() => {
    setIsLoadingListIncident(true);
    if (passcode_enabled === true) {
      if (!values) {
        return history.push('/message/check');
      }
      return getIncidentList(values)
        .then((res) => {
          if (res) {
            handleListIncidentSuccess(res.data);
          }
        })
        .catch((err) => {
          handleListIncidentError(err);
        })
        .finally(() => {
          setIsLoadingListIncident(false);
        });
    }
    return getListInicdentNotQuery()
      .then((res) => {
        if (res) {
          handleListIncidentSuccess(res.data);
        }
      })
      .catch((err) => {
        handleListIncidentError(err);
      })
      .finally(() => {
        setIsLoadingListIncident(false);
      });
  }, []);

  const chooseInicdentDetail = (id: any) => {
    history.push(`/message/detail/${id}`);
  };

  const handleUploadFile = (fileList: any[]) => {
    setIsLoadingUpload(true);
    let arrayKey: any = [];

    if (fileList?.length > 0) {
      Object.keys(fileList).forEach(function (key) {
        arrayKey = arrayKey.concat(fileList[key]);
      });
      arrayKey = arrayKey.map((e) => {
        return {
          name: encodeURIComponent(e.name),
          type:
            e.name.split('.').pop() === 'heic' || e.name.split('.').pop() === 'HEIC'
              ? 'HEIC'
              : e.type || `${e.name.split('.').pop()}`,
          size: e.size,
          file: e,
        };
      });

      customRequest(arrayKey);
    }
  };

  const customRequest = async (fileList: any[]) => {
    setIsLoadingUpload(true);

    const isAllowed = [
      'image/jpeg',
      'image/png',
      'audio/mpeg',
      'video/mp4',
      'heic',
      'video/quicktime',
      'MOV',
      'mov',
      'jpeg',
      'png',
      'mpeg',
      'mp4',
    ];
    const fileType = fileList?.map((e) => isAllowed.includes(e?.type.toLowerCase()));

    const isFileTobig = fileList?.find((e) => e.size > 100 * 1024 * 1024);

    if (fileType.includes(false)) {
      setIsLoadingUpload(false);

      inputFileRef.current.value = '';
      return showError(t('chat.menu.check.allowed'));
    }

    if (isFileTobig) {
      setIsLoadingUpload(false);
      inputFileRef.current.value = '';
      return showError(t('chat.menu.check.bigfile', { size: 100 }));
    }
    const isFileToSmall = fileList?.find((e) => e.size <= 0);

    if (isFileToSmall) {
      setIsLoadingUpload(false);
      inputFileRef.current.value = '';
      return showError(t('chat.report.file.small'));
    }
    if (fileList.length > 10 || fileList.length + FileMessage.files.length > 10) {
      setIsLoadingUpload(false);
      inputFileRef.current.value = '';
      return showError(t('chat.menu.check.maxfile', { number: 10 }));
    }

    try {
      let jsonCheck: any = {};

      for (let i = 0; i < fileList.length; i++) {
        jsonCheck[fileList[i].name] = {
          file: fileList[i].file,
          type: fileList[i].type,
        };
      }

      const payload = {
        files: [...fileList].map((eel) => eel.name),
      };
      const Urls = await upLoad(payload);

      const dataRemap = (Urls?.data || []).map((ele) => {
        return {
          url: ele.url,
          ...jsonCheck[ele.original_name],
          fileKey: ele.fileKey,
          original_name: ele.original_name,
        };
      });

      nProgress.start();

      Promise.all(
        dataRemap.map((item: any) => {
          const config = {
            headers: {
              'Content-Type': item.type,
              'Access-Control-Allow-Origin': '*',
            },
          };

          return axios.put(item.url, item.file, config);
        })
      )
        .then(() => {
          const newFiles: any[] = dataRemap.map((item: any) => {
            return { fileKey: item?.fileKey, original_name: item?.original_name };
          });
          inputFileRef.current.value = '';
          setFileMessage({ ...FileMessage, files: [...FileMessage.files, ...newFiles] });
        })
        .finally(() => {
          nProgress.done();
          setIsLoadingUpload(false);
        });
    } catch (error) {
      inputFileRef.current.value = '';
      setIsLoadingUpload(false);
    }
  };
  const reloadListIncident = useCallback(async () => {
    if (passcode_enabled) {
      if (!values) {
        return history.push('/message/check');
      } else {
        await getIncidentList(values)
          .then((res) => {
            handleListIncidentSuccess(res.data);
          })
          .catch((err) => {
            if (err) {
              if (err?.data?.status.toString() === 'ERROR') {
                showError(I18n.t(`errors.code.${err?.data?.error_code}`));
              }
            }
          });
      }
    } else {
      await getListInicdentNotQuery()
        .then((res) => {
          handleListIncidentSuccess(res.data);
        })
        .catch((err) => {});
    }
  }, [values]);
  const handleReadMessage = async (incident_id: any) => {
    if (incident_id) {
      const payload: IPostReadChatIncidentFormValues = {
        incident_id: incident_id,
      };
      await postReadChatAnonymouse(payload)
        .then(() => {
          countNewMessageUnread();
          reloadListIncident();
        })
        .catch((err) => {});
    }
  };
  const postAttchMentFile = (files: any) => {
    const data = {
      files: files,
      incident_id: itemSelected?.incident_id,
    };

    postAttchmentFile(data)
      .then((res) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      })
      .catch((err) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      });
  };

  const handleRemoveUpload = (file) => {
    const indexPosition = FileMessage.files.findIndex((e) => e.original_name === file.name);
    FileMessage.files.splice(indexPosition, 1);
    setFileMessage({ files: FileMessage.files });
  };

  const onSendMessage = async (item: any) => {
    if (itemSelected?.incident_id) {
      if (FileMessage.files.length > 0) {
        await postAttchMentFile(FileMessage.files);
        const messageSubmit = {
          text: item,
          type: 'file',
          files: FileMessage.files.length,
        };
        setFileMessage({ files: [] });
        sendMessage(itemSelected?.incident_id, messageSubmit);
      } else {
        if (item?.trim('')) {
          sendMessage(itemSelected?.incident_id, item?.trim(''));
        }
      }
    }
    await handleReadMessage(itemSelected?.incident_id);
    await reloadListIncident();
  };

  return (
    <>
      {Array.isArray(listIncident) && listIncident.length <= 0 ? (
        <Row className="chat_component">
          <Col span={24} className="col_notfound">
            <WhiteBox />
            <Text className="notfound_text">{t('chat.empty')}</Text>
          </Col>
        </Row>
      ) : (
        <Row className="chat_component" gutter={16}>
          <Modal
            width={394}
            zIndex={700}
            visible={isModalVisible}
            footer={null}
            closable={false}
            className="modal-hideincident"
          >
            <Text className="text_modal">{t(`chat.hide.title`)}</Text>
            <div className="content-modal">
              <Button type="ghost" className="button_modal cancel" onClick={handleCancel}>
                {t('chat.hide.cancel')}
              </Button>
              <Button type="primary" className="button_modal" onClick={handleHideIncident}>
                {t('chat.hide.ok')}
              </Button>
            </div>
          </Modal>
          <ListIncident
            itemSelected={itemSelected}
            listIncident={listIncident}
            chooseValue={chooseValue}
            chooseInicdentDetail={chooseInicdentDetail}
            ShowModal={ShowModal}
            reloadListIncident={reloadListIncident}
            countNewMessageUnread={countNewMessageUnread}
            isLoadingListIncident={isLoadingListIncident}
          />
          <Col span={16} className="col right">
            <Spin spinning={isLoading}>
              {checkRenderListMessage() && chats ? (
                <Card
                  title={t(`chat.incident.title`, { incident: incident_number })}
                  className="chat_content"
                >
                  <ListMessage
                    chats={chats}
                    loadingGetListMessageChat={loadingGetListMessageChat}
                    itemSelected={itemSelected}
                    setIsClosedInputChat={setIsClosedInputChat}
                  />
                </Card>
              ) : null}
            </Spin>
            {checkRenderInputChat() ? (
              <Card className="card_sendmessage">
                <FormChat
                  ref={inputRef}
                  isClosed={itemSelected.is_closed}
                  inputFileRef={inputFileRef}
                  listIncident={listIncident}
                  reloadListIncident={reloadListIncident}
                  isLoading={isLoadingUpload}
                  handleUploadFile={handleUploadFile}
                  indexFile={FileMessage.files.length}
                  onSendMessage={onSendMessage}
                  handleReadMessage={handleReadMessage}
                  activeIncidentId={itemSelected?.incident_id}
                  handleRemoveUpload={handleRemoveUpload}
                  FileMessage={FileMessage}
                />
              </Card>
            ) : null}
          </Col>
        </Row>
      )}
    </>
  );
};

export default ChatPage;
