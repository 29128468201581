import { NotFound } from 'components/common';
import LoginPage from 'features/auth/pages/login/LoginPage';
import SelectPage from 'features/auth/pages/select/SelectPage';
import TermsPage from 'features/auth/pages/term/TermsPage';
import DetailContentPage from 'features/content/page/detail/detail';
import ContentPage from 'features/content/page/info';
import HomePage from 'features/home/pages/homepage';
import ReportPage from 'features/incident/pages/report/ReportPage';
import ReportSuccess from 'features/incident/pages/success/success';
import ChatPage from 'features/message/page/chat';
import CheckMessage from 'features/message/page/check/check';
import IncidentDetail from 'features/message/page/detail/detail';
import Info from 'features/passcode/pages/Info/Info';
import SetPassCode from 'features/passcode/pages/PassCode/Create';
import UpdatePassCode from 'features/passcode/pages/PassCode/update';
import Success from 'features/passcode/pages/Success/success';
import SOS from 'features/sos/pages';

const routes = [
  {
    path: '/report',
    component: ReportPage,
  },
  {
    path: '/success',
    component: ReportSuccess,
  },
  {
    path: '/message/check',
    component: CheckMessage,
    keyRoute: '2',
  },
  {
    path: '/message/detail/:id',
    component: IncidentDetail,
    keyRoute: '2',
  },
  {
    path: '/',
    component: HomePage,
    keyRoute: '1',
    auth: true,
    layout: true,
  },
  {
    path: '/SOS',
    component: SOS,
    auth: true,
    layout: true,
    keyRoute: '3',
  },
  {
    path: '/content',
    component: ContentPage,
    auth: true,
    layout: true,
    keyRoute: '4',
  },
  {
    path: '/message',
    component: ChatPage,
    keyRoute: '2',
    auth: true,
    layout: true,
  },
  {
    path: '/detail',
    component: DetailContentPage,
    auth: true,
    layout: true,
    keyRoute: '4',
  },
  {
    path: '/auth/login',
    component: LoginPage,
  },
  {
    path: '/auth/select',
    component: SelectPage,
  },
  {
    path: '/auth/term',
    component: TermsPage,
  },
  {
    path: '/passcode/info',
    component: Info,
  },
  {
    path: '/passcode/newpass',
    component: SetPassCode,
  },
  {
    path: '/passcode/change',
    component: UpdatePassCode,
  },
  {
    path: '/passcode/success',
    component: Success,
  },
  {
    path: '/404',
    component: NotFound,
  },
];

export default routes;
