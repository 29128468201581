import { Badge, Card, Col, List, Space, Spin, Typography } from 'antd';
import Folder from 'components/common/icon-svg/folder';
import { getLimitMessage } from 'features/message/api';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { storage } from 'utils';

interface IListIncidentProps {
  chooseInicdentDetail: (id: string) => void;
  chooseValue: (item: any) => void;
  ShowModal: (id?: any) => void;
  reloadListIncident: () => void;
  countNewMessageUnread: () => void;
  listIncident?: any[];
  isLoadingListIncident: boolean;
  itemSelected: any;
}
const ListIncident = ({
  chooseValue,
  chooseInicdentDetail,
  ShowModal,
  listIncident,
  isLoadingListIncident,
  itemSelected,
}: IListIncidentProps) => {
  const { t } = useTranslation();
  const client_id = storage.getClientId();
  const [limitMessage, setLimitMessage] = useState<any>();
  const [isLoadingMessageSetting, setIsLoadingMessageSetting] = useState<boolean>(false);

  useEffect(() => {
    getLimit();
  }, []);

  const getLimit = useCallback(async () => {
    setIsLoadingMessageSetting(true);
    try {
      const result = await getLimitMessage();
      setLimitMessage(result?.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingMessageSetting(false);
    }
  }, []);

  const handChooseIncident = (id) => {
    chooseInicdentDetail(id);
  };

  return (
    <Col span={8} className="col">
      <Card
        className="list_incident"
        style={{
          height: limitMessage && limitMessage?.type.toUpperCase() !== 'UNLIMITED' ? '' : '100%',
        }}
      >
        <Spin spinning={isLoadingListIncident}>
          <List
            className="list_info"
            size="large"
            bordered
            locale={{ emptyText: t('no_data', { name: t('list.empty.incident') }) }}
          >
            {Array.isArray(listIncident) &&
              listIncident.length > 0 &&
              listIncident?.map((item: any, index: any) => {
                const background = itemSelected?.incident_id === item.incident_id ? '#D3F2FD' : '';
                const colorText =
                  itemSelected?.incident_id === item.incident_id ? '#777777' : ' #404040';

                return (
                  <List.Item
                    className="list_message"
                    key={index}
                    style={{ background: background }}
                  >
                    <Badge count={item?.message_unreads} color="#ff3b30" offset={[-8, 45]}>
                      <div className="image" onClick={() => handChooseIncident(item?.incident_id)}>
                        <div className="svg-image">
                          <Folder />
                        </div>
                      </div>
                    </Badge>
                    <Space
                      className="info_chat"
                      style={{ paddingBottom: item?.last_message ? '' : '20px' }}
                      onClick={() => chooseValue(item)}
                    >
                      <Typography.Text className="top-title">
                        {t(`chat.incident.title`, { incident: item.incident_number })}
                      </Typography.Text>
                      {item?.files ? (
                        <Typography.Paragraph
                          ellipsis={{ rows: 2, expandable: false }}
                          className="bottom-title"
                          style={{ color: colorText }}
                        >
                          {t('chat.detail.attachment', { number: item.files })}
                        </Typography.Paragraph>
                      ) : null}
                      {item?.last_message ? (
                        <Typography.Paragraph
                          ellipsis={{ rows: 2, expandable: false }}
                          className="bottom-title"
                          style={{ color: colorText }}
                        >
                          {item.last_message}
                        </Typography.Paragraph>
                      ) : null}
                    </Space>
                    <div className="info_time">
                      <Typography.Text className="text_time">
                        {item?.last_message && moment(item?.createdAt).format('HH:mm')}
                      </Typography.Text>
                      <Typography.Text
                        className="hide_text"
                        onClick={() => ShowModal(item?.incident_id)}
                      >
                        {t('chat.list.hide_incident')}
                      </Typography.Text>
                    </div>
                  </List.Item>
                );
              })}
          </List>
        </Spin>
      </Card>

      <Card
        className="card_info"
        style={{
          display: limitMessage && limitMessage?.type.toUpperCase() !== 'UNLIMITED' ? '' : 'none',
        }}
      >
        <Space
          style={{ margin: 0, paddingLeft: 0, listStylePosition: 'inside' }}
          className="space-note"
        >
          <Spin spinning={isLoadingMessageSetting}>
            {limitMessage && limitMessage?.type.toUpperCase() !== 'UNLIMITED' && (
              <Typography.Text className="text-note">
                {limitMessage?.type === 'BY_TOTAL'
                  ? t('chat.limit.message.total', { total: limitMessage?.value })
                  : t('chat.limit.message.date', { date: limitMessage?.value })}
              </Typography.Text>
            )}
          </Spin>
        </Space>
      </Card>
    </Col>
  );
};

export default ListIncident;
