// eslint-disable-next-line react-hooks/exhaustive-deps

import { Button, Row, Space, Typography } from 'antd';
import SymbolHome from 'components/common/icon-svg/symbolHome';
import ModalGlobal from 'components/common/showModal/modalGlobal';
import { API_URL } from 'config';
import { ApiPassCode } from 'features/passcode/api';
import { axios } from 'lib';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { storage } from 'utils';
import './index.scss';

const { Title, Text } = Typography;

interface IHomeProps {
  info: any;
}
const HomePage = ({ info }: IHomeProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const location: any = useLocation();
  const firstTime = storage.getFirstTime();
  const locationState = location.state;

  useEffect(() => {
    if (firstTime && !locationState?.forceRemove) {
      handleCallPopup();
    } else {
      if (locationState?.action === 'REMOVE') return setIsModalVisible(true);
    }
  }, [firstTime]);

  useEffect(() => {
    storage.setKeyMenu('1');
  }, []);

  useEffect(() => {
    callPasscode();
  }, []);

  const callPasscode = async () => {
    const data = {
      action: 'STATUS',
      confirm_passcode: '',
      current_passcode: '',
      new_passcode: '',
    };
    const result = await ApiPassCode(data);
    if (result && result.data) {
      storage.setCurentPasscodeEnable(result?.data?.passcode_enabled);
    }
  };

  const handleCallPopup = async () => {
    const data = {
      action: 'STATUS',
      confirm_passcode: '',
      current_passcode: '',
      new_passcode: '',
    };

    const result = await ApiPassCode(data);

    storage.setCurentPasscodeEnable(result?.data?.passcode_enabled);
    if (!result?.data?.passcode_enabled) {
      setIsModalVisible(true);
      storage.setFirtTime(false);
    }
  };
  const handleOk = () => {
    setIsModalVisible(false);
    storage.setFirtTime(false);
    if (locationState?.action === 'REMOVE') {
      history.push({
        pathname: '/passcode/change',
        state: { action: 'REMOVE' },
      });
    } else {
      history.push({
        pathname: '/passcode/info',
      });
    }
  };
  const handleCancel = () => {
    history.push({
      pathname: '/',
    });
    setIsModalVisible(false);
  };

  const goReport = () => {
    history.push({ pathname: '/report' });
  };

  const handleChangeKeyMenu = () => {
    storage.setKeyMenu('3');
  };

  return (
    <Row className="home_component">
      <ModalGlobal
        visible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        contentText={
          locationState?.action === 'REMOVE'
            ? t(`passcode.remove_confirm.title`)
            : t(`home.suggestion.title`)
        }
        type=""
        text={
          locationState?.action === 'REMOVE'
            ? t('passcode.remove_confirm.ok')
            : t('home.suggestion.ok')
        }
        textCancel={
          locationState?.action === 'REMOVE'
            ? t('passcode.remove_confirm.cancel')
            : t('home.suggestion.cancel')
        }
      />
      <div className="card_image">
        <img className="top_image" src="/image/background.png" alt="" />
        <div className="logo">
          <div className="Oval" onClick={goReport}>
            <img className="img-oval" src="/image/Oval.png" alt="" />
            <div className="logo_image">
              <Space direction="vertical" className="space-logo" size={16}>
                <SymbolHome />
                {t('home.button.report')}
              </Space>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="cardbottom">
          {info?.organization_name && (
            <Title className="cardbottom_title">{info?.organization_name}</Title>
          )}
          {info?.home_message && info?.working_time ? (
            <Text className="cardbottom_text">{info?.home_message}</Text>
          ) : (
            <div className="cardbottom_text_without_time">
              <Text className="cardbottom_text">{info?.home_message}</Text>{' '}
            </div>
          )}
          {info?.working_time && <Text className="cardbottom_button">{info?.working_time}</Text>}
          {info?.working_time ? (
            <Link
              to="/SOS"
              onClick={handleChangeKeyMenu}
              style={{ textDecoration: 'underline', color: '#777777' }}
            >
              {t(`home.sos_link`)}
            </Link>
          ) : (
            <Link
              to="/SOS"
              onClick={handleChangeKeyMenu}
              style={{ textDecoration: 'underline', color: '#777777', marginTop: '15px' }}
            >
              {t(`home.sos_link`)}
            </Link>
          )}
        </div>
      </div>
    </Row>
  );
};

export default HomePage;
