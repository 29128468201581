import { Card, Col, Row, Typography, Button, Space } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { useEffect, useState } from 'react';
import './detail.scss';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Iframe from 'react-iframe';
import {
  downloadFileDetail,
  getListContentDetail,
  IDownloadFileDetail,
  IQueryGetContentDetail,
} from 'features/content/api';
import moment from 'moment';
import ArrowLeft from 'components/common/icon-svg/arrowleft';
import AttachContent from 'components/common/icon-svg/attachContent';
import { Content } from 'antd/lib/layout/layout';

type ListArticle = {
  content: string;
  createdAt: Date;
  deleted: boolean;
  id: string;
  published: boolean;
  source_url: string;
  thumbnail_url: string;
  title: string;
  updatedAt: Date;
  __v: number;
  _id: string;
  date_published: Date;
};

interface INotice {
  createdAt: Date;
  deleted: boolean;
  content: string;
  description: string;
  date_published: Date;
  file_url: [
    {
      fileKey: string;
      original_name: string;
    }
  ];
  published: boolean;
  source_url: string;
  title: string;
  updatedAt: Date;
  __v: number;
  _id: string;
  id: string;
}
interface IDataBroadCase {
  createdAt: Date;
  deleted: boolean;
  files: any[];
  message?: string;
  content?: string;
  org_id: string;
  schedule: string;
  status: string;
  subject: string;
  updatedAt: Date;
  __v: number;
  _id: string;
}
enum ETypeQuery {
  ARTICLE = 'ARTICLE',
  BROADCAST = 'BROADCAST',
  NOTICE = 'NOTICE',
}
const { Text } = Typography;

interface IDetailProps {
  getCountNumber: () => void;
}
const DetailContentPage = ({ getCountNumber }) => {
  const [article, setArticle] = useState<ListArticle>();
  const [broadcast, setBroadCast] = useState<IDataBroadCase>();
  const [notice, setNotice] = useState<INotice>();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const query: any = queryString.parse(location.search);
  let type: any = Object.keys(query).toString();
  let detail_id: any = Object.values(query)[0];
  useEffect(() => {
    loadingDetailContent();
    getCountNumber();
  }, []);

  const loadingDetailContent = () => {
    if (query) {
      const query: IQueryGetContentDetail = {
        type: type,
        id: detail_id,
      };
      getListContentDetail(query)
        .then((res) => {
          if (type === ETypeQuery.ARTICLE) {
            setArticle(res.data);
          } else if (type === ETypeQuery.BROADCAST) {
            setBroadCast(res.data);
          } else {
            setNotice(res.data);
          }
        })
        .catch((err) => {});
    }
  };

  const postFileDownload = async (item: any) => {
    const data: IDownloadFileDetail = { files: [item?.fileKey], is_download: true };

    const result = await downloadFileDetail(data);

    window.open(result?.data[0]?.url);
  };

  const handleGoback = () => {
    history.push('/content');
    getCountNumber();
  };

  const renderNew = (time) => {
    if (moment().diff(moment(time, 'YYYY/MM/DD'), 'days') <= 10)
      return <div className="new">{t('article.new')}</div>;
  };

  const renderValue = (time) => {
    if (article && moment().diff(moment(time, 'YYYY/MM/DD'), 'days') <= 10) {
      return true;
    }
    return false;
  };
  return (
    <>
      <Row className="detail_container">
        <Text className="title" onClick={handleGoback}>
          <Space size={8}>
            <ArrowLeft />
            {t('login.back')}
          </Space>
        </Text>
        <Col className="col">
          <Card className="card_article">
            <Meta
              title={article ? article?.title : broadcast ? broadcast?.subject : notice?.title}
              description={
                <div style={{ overflow: 'auto', height: '97%' }}>
                  <div className="card_image">
                    {article && renderNew(article?.date_published)}

                    <Text
                      className="time_text"
                      style={{ marginLeft: renderValue(article?.date_published) ? '8px' : '0' }}
                    >
                      {article && moment(article?.date_published).format('YYYY/MM/DD HH:mm')}
                      {broadcast && moment(broadcast?.createdAt).format('YYYY/MM/DD HH:mm')}
                      {notice && moment(notice?.date_published).format('YYYY/MM/DD HH:mm')}
                    </Text>
                  </div>
                  <div className="card_bottom">
                    <div
                      className="bottom_image"
                      style={{
                        height: article ? '100%' : '',
                        paddingBottom: article ? '23px' : '',
                      }}
                    >
                      {article ? (
                        <>
                          <div className="iframe">
                            <Iframe
                              url={article?.source_url}
                              id="myId"
                              width="100%"
                              className="myClassname"
                              height="100%"
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          {broadcast &&
                            Array.isArray(broadcast?.files) &&
                            broadcast?.files?.length > 0 &&
                            broadcast?.files?.map((item, index) => {
                              return (
                                <Button
                                  size="large"
                                  placeholder="large size"
                                  className="btn"
                                  key={index}
                                  onClick={() => postFileDownload(item)}
                                >
                                  <AttachContent />

                                  <Text className="name">
                                    {decodeURIComponent(item.original_name.split('.')[0])}
                                  </Text>
                                  <Text className="name-keyimage">
                                    .{item.original_name.split('.')[1]}
                                  </Text>
                                </Button>
                              );
                            })}
                          {notice &&
                            Array.isArray(notice?.file_url) &&
                            notice?.file_url?.length > 0 &&
                            notice?.file_url?.map((item, index) => {
                              return (
                                <Button
                                  size="large"
                                  placeholder="large size"
                                  className="btn"
                                  key={index}
                                  onClick={() => postFileDownload(item)}
                                >
                                  <AttachContent />
                                  <Text className="name">
                                    {decodeURIComponent(item.original_name.split('.')[0])}
                                  </Text>
                                  <Text className="name-keyimage">
                                    .{item.original_name.split('.')[1]}
                                  </Text>
                                </Button>
                              );
                            })}
                          <div className="message-container">
                            <Text className="message">
                              {broadcast
                                ? broadcast?.message || broadcast?.content
                                : notice?.description || notice?.content}
                            </Text>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              }
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DetailContentPage;
