import { Checkbox, Form, Select } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import { Trans, useTranslation } from 'react-i18next';
interface IProps {
  listCheckBoxList: any;
  changeAnswerCheckboxList: (e: any, data: any) => void;
  answersCheckBoxList: any;
}

const { Option } = Select;
export default function ListCheckBoxList({
  listCheckBoxList,
  changeAnswerCheckboxList,
  answersCheckBoxList,
}: IProps) {
  const { t } = useTranslation();

  const findValue = answersCheckBoxList.find((e) => e?._id === listCheckBoxList?._id);

  return (
    <>
      {listCheckBoxList?.answer_value.length > 0 && (
        <Form.Item
          className={listCheckBoxList?.is_required ? 'checkboxlist' : 'before'}
          name={listCheckBoxList?._id}
          label={
            !listCheckBoxList?.is_required ? (
              listCheckBoxList?.question_name
            ) : (
              <Trans
                i18nKey="report.new.question_lists.required.test"
                components={{
                  question: listCheckBoxList?.question_name,
                  red: <span style={{ display: 'inline-block', color: 'red' }} />,
                  default: <span style={{ display: 'inline-block', color: 'black' }} />,
                }}
              />
            )
          }
          valuePropName="checked"
          rules={[
            {
              required: listCheckBoxList?.is_required,
              message: t('report.new.question_lists.required'),
            },
          ]}
        >
          <Select
            className="custom-select"
            mode="multiple"
            style={{ width: '100%' }}
            placeholder={t('report.new.checkbox_lists')}
            onChange={(value) => changeAnswerCheckboxList(value, listCheckBoxList)}
            clearIcon={<CloseCircleFilled />}
            optionLabelProp="label"
            showArrow={true}
            showSearch={false}
            allowClear={true}
          >
            {listCheckBoxList?.answer_value.map((item, index) => {
              const currentValue = findValue?.answer.filter((e) => e === item)[0];
              return (
                <Option value={item} label={item} key={index}>
                  <div className="demo-option-label-item">{item}</div>
                  <Checkbox checked={currentValue === item} />
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      )}
    </>
  );
}
