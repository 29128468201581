import { Form, Radio, Select } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
interface IProps {
  listDropdown: any;
  changeAnswerDropDown: (e: any, data) => void;
  answersDropDown: any;
}

const { Option } = Select;

export default function ListDropdown({
  listDropdown,
  changeAnswerDropDown,
  answersDropDown,
}: IProps) {
  const { t } = useTranslation();
  return (
    <>
      {listDropdown?.answer_value.length > 0 && (
        <Form.Item
          className={listDropdown?.is_required ? 'dropdown' : 'before'}
          name={listDropdown?._id}
          label={
            !listDropdown?.is_required ? (
              listDropdown?.question_name
            ) : (
              <Trans
                i18nKey="report.new.question_lists.required.test"
                components={{
                  question: listDropdown?.question_name,
                  red: <span style={{ display: 'inline-block', color: 'red' }} />,
                  default: <span style={{ display: 'inline-block', color: 'black' }} />,
                }}
              />
            )
          }
          rules={[
            {
              required: listDropdown?.is_required,
              message: t('report.new.question_lists.required'),
            },
          ]}
        >
          <Select
            optionLabelProp="label"
            placeholder={t('report.new.dropdown_lists')}
            onChange={(e) => changeAnswerDropDown(e, listDropdown)}
            className="select-dropdown"
          >
            {listDropdown?.answer_value?.map((e, index) => {
              const findValue = answersDropDown.find((e) => e?._id === listDropdown?._id);
              return (
                <Option className="menu-item" key={index} value={e}>
                  {e}
                  <Radio
                    checked={e === findValue?.answer}
                    className={e === findValue?.answer ? ' active-checked' : ''}
                  />
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      )}
    </>
  );
}
