interface ICurrentState {
  list: any[];
}

const storage = {
  getIsCallDeactive: (): any => {
    return window.localStorage.getItem('isCallDeactive') || '';
  },
  getClientId: (): any => {
    return window.localStorage.getItem('clientId') || '';
  },
  getKeyMenu: (): string => {
    return window.localStorage.getItem('key') || '';
  },
  getRefreshToken: (): string => {
    return window.localStorage.getItem('refreshToken') || '';
  },
  getToken: (): string => {
    return window.localStorage.getItem('accessToken') || '';
  },
  getCurentUser: () => {
    if (window.localStorage.getItem(`currentUser`)) {
      return JSON.parse(window.localStorage.getItem(`currentUser`) as string);
    }
    return null;
  },
  getCurentOrganization: () => {
    if (window.localStorage.getItem(`curentOrganization`)) {
      return JSON.parse(window.localStorage.getItem(`curentOrganization`) as string);
    }
    return null;
  },

  getCurentPasscodeEnable: () => {
    const passcodeEnable = window.localStorage.getItem(`passcodeEnable`);
    if (!passcodeEnable || passcodeEnable === 'undefined') {
      return null;
    }
    return JSON.parse(window.localStorage.getItem(`passcodeEnable`) as string);
  },

  getVersionApp: () => {
    return window.localStorage.getItem(`version_app`);
  },

  setVersionApp: (version) => {
    return window.localStorage.setItem(`version_app`, version);
  },

  getActiveId: () => {
    if (window.localStorage.getItem(`activeId`)) {
      return JSON.parse(window.localStorage.getItem(`activeId`) as string);
    }
    return null;
  },
  setActiveId: (activeId: any) => {
    window.localStorage.setItem(`activeId`, JSON.stringify(activeId));
  },

  clearKeyMenu: () => {
    window.localStorage.removeItem(`key`);
  },
  setCurrentState: (currentState: ICurrentState) => {
    window.localStorage.setItem(`currentState`, JSON.stringify(currentState));
  },

  setCurentPasscodeEnable: (currentPasscode) => {
    window.localStorage.setItem(`passcodeEnable`, JSON.stringify(currentPasscode || false));
  },

  setFirtTime: (fisrtTime: boolean) => {
    window.localStorage.setItem(`fisrtTime`, JSON.stringify(fisrtTime));
  },

  getFirstTime: () => {
    if (window.localStorage.getItem(`fisrtTime`)) {
      return JSON.parse(window.localStorage.getItem(`fisrtTime`) as any);
    }
    return null;
  },

  getCurrentState: () => {
    if (window.localStorage.getItem(`currentState`)) {
      return JSON.parse(window.localStorage.getItem(`currentState`) as any);
    }
    return null;
  },

  clearCurentPasscodeEnable: () => {
    window.localStorage.removeItem(`passcodeEnable`);
  },

  clearCurrentState: () => {
    window.localStorage.removeItem(`currentState`);
  },
  clearClientId: () => {
    window.localStorage.removeItem(`clientId`);
  },
  clearFisrtTime: () => {
    window.localStorage.removeItem(`fisrtTime`);
  },

  setKeyMenu: (key: any) => {
    window.localStorage.setItem(`key`, key);
  },
  setClientId: (clientId: string) => {
    window.localStorage.setItem(`clientId`, clientId);
  },
  setIsCallDeactive: (isCallDeactive: string) => {
    window.localStorage.setItem(`isCallDeactive`, isCallDeactive);
  },

  setToken: (token: string) => {
    window.localStorage.setItem(`accessToken`, token);
  },
  setRefreshToken: (token: string) => {
    window.localStorage.setItem(`refreshToken`, token);
  },

  setCurentOrganization: (data: object) => {
    window.localStorage.setItem(`curentOrganization`, JSON.stringify(data));
  },
  setCurrentUser: (data: object) => {
    window.localStorage.setItem(`currentUser`, JSON.stringify(data));
  },
  clearActiveId: () => {
    window.localStorage.removeItem(`activeId`);
  },
  clearToken: () => {
    window.localStorage.removeItem(`accessToken`);
  },
  clearRefreshToken: () => {
    window.localStorage.removeItem(`refreshToken`);
  },
  clearCurentOrganization: () => {
    window.localStorage.removeItem(`curentOrganization`);
  },
  clearAll: () => {
    window.localStorage.clear();
  },

  setShowErrorORG: () => {
    window.localStorage.setItem(`org_error`, '1');
  },
  getShowErrorORG: () => {
    window.localStorage.getItem(`org_error`);
  },
};

export const clearAll = (cb?: any) => {
  const clientId = storage.getClientId();
  const versionApp = storage.getVersionApp();
  localStorage.clear();
  storage.setClientId(clientId);
  storage.setVersionApp(versionApp);
  if (cb) cb();
};

export { storage };
