import { DatePicker, Form } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import locale from 'antd/es/date-picker/locale/ja_JP';

interface IProps {
  listDate: any;
  answersDate: (e, data) => void;
}

export default function ListDate({ listDate, answersDate }: IProps) {
  const { t } = useTranslation();
  return (
    <>
      <Form.Item
        className={listDate?.is_required ? 'date' : 'before'}
        name={`${listDate?._id}.date`}
        label={
          !listDate?.is_required ? (
            listDate?.question_name
          ) : (
            <Trans
              i18nKey="report.new.question_lists.required.test"
              components={{
                question: listDate?.question_name,
                red: <span style={{ display: 'inline-block', color: 'red' }} />,
                default: <span style={{ display: 'inline-block', color: 'black' }} />,
              }}
            />
          )
        }
        rules={[
          {
            required: listDate?.is_required,
            message: t('report.new.question_lists.required'),
          },
        ]}
      >
        <DatePicker
          placeholder={t('report.new.datepicker')}
          format="YYYY/MM/DD"
          onChange={(e) => answersDate(e, listDate)}
          locale={locale}
        />
      </Form.Item>
    </>
  );
}
